<template>
    <b-container fluid>
          <b-row>
            <b-col cols="1"></b-col>
            <b-col cols="10">
              <FullCalendar @datesSet="nextBtnEvent" :options='calendarOptions'>
            <template v-slot:eventContent='arg'>
                <div class="text-center" :style="{backgroundColor: arg.event.extendedProps.status == 'confirm' ? '#16a085' : '#2980b9'}">
                  <span style="font-size: 14px;">
                    {{ arg.event.title }}
                  </span>
                  <span>
                    <!-- {{ arg.event.extendedProps.rooms }} -->
                  </span>
                </div>
            </template>
          </FullCalendar>
            </b-col>
            <b-col cols="1"></b-col>
          </b-row>
    </b-container>
  </template>
<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
// import listPlugin from '@fullcalendar/list'
// import timeGridPlugin from '@fullcalendar/timegrid'

export default {
  name: 'App',
  components: {
    FullCalendar
  },
  data () {
    return {
      showRoom: {},
      selectedDate: '',
      payload: {
        price: '',
        start_date: '',
        end_date: ''
      },
      editPayload: {
        price: ''
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        height: 680,
        weekends: true,
        dayMaxEvents: 3,
        eventClick: this.handleDateClick,
        datesSet: this.nextBtnEvent,
        eventColor: 'transparent',
        events: [],
        showNonCurrentDates: false
      }
    }
  },
  watch: {

  },
  methods: {
    handleDateClick: function (clickInfo) {
      this.$router.push(`/reservations-info/${clickInfo.event._def.extendedProps.revId}`)
    },
    getReservationsData (date) {
      this.getReservations(date).then(() => {
        var eventData = []
        // const flatRoomsArr = this.reservstions.reduce((acc, cv) => {
        //   acc = [...acc, ...cv.rooms.map(r => ({ ...r, data: cv.date }))]
        //   return acc
        // }, [])
        this.reservstions.forEach(el => {
          eventData.push({
            title: el.full_name || el.client.name,
            start: el.checkin,
            end: el.checkout,
            status: el.status,
            revId: el.id
          })
        })
        this.calendarOptions.events = eventData
      })
    },
    nextBtnEvent (info) {
      this.getReservationsData(info.startStr.slice(0, 7))
      // this.getPriceData(
      //   {
      //     from: info.startStr.slice(0, 10),
      //     to: info.endStr.slice(0, 10)
      //   }
      // )
    }

  },

  mounted () {
  }
}
</script>
<style>
.fc-scrollgrid-sync-table {
  width: 100% !important;
}
.room-info .info{
  border: 2px dashed #ccc;
}

</style>
